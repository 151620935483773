<template>
	<form class="main-wrapper mt-4">
		<CRow class="mb-5">
			<CCol md="12">
				<CRow>
					<CCol md="8">
						<h4>{{ title }}</h4>
						<span class="typo-body-2 color-black-45">{{ description }}</span>
					</CCol>
				</CRow>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localInterest.$model"
						:is-valid="!$v.localInterest.$error"
						:invalid-feedback="$t('global.error.required')"
						:decimal-scale="2"
						label="Interest rate*"
						append-text="%"
					/>
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						v-model="$v.localPeriod.$model"
						:is-valid="!$v.localPeriod.$error"
						invalid-feedback="Month must be greater than 0"
						class="input-period"
						label="Payment term*"
						append-text="Month"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>
			<CCol md="12">
				<h4>Order value</h4>
				<div class="typo-body-2 color-black-45">
					<span>
						Set min-max of the purchase order value for this plan, if any.
					</span>
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="minOrder"
						v-model="localMinOrder"
						:decimal-scale="2"
						label="Minimum order"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
						allow-null-value
					/>
				</div>
			</CCol>
			<CCol md="4">
				<div class="form-group mt-3">
					<BaseInputNumber
						id="maxOrder"
						v-model="localMaxOrder"
						:decimal-scale="2"
						:is-valid="!$v.localMaxOrder.$error"
						:invalid-feedback="maxOrderErrorMessage"
						label="Maximum order"
						prepend-text="฿"
						text-align="right"
						placeholder="0.00"
						allow-null-value
					/>
				</div>
			</CCol>
			<CCol md="12">
				<hr class="mb-5">
			</CCol>

			<template v-if="isBAYBankAgent">
				<CCol md="12">
					<h4>Marketing code and product ID</h4>
					<span class="typo-body-2 color-black-45">Set marketing code and product ID for this installment plan.</span>
				</CCol>
				<CCol md="6">
					<div class="form-group mt-3">
						<CInput
							v-model.trim="$v.localCampaignCode.$model"
							:is-valid="!$v.localCampaignCode.$error && null"
							:invalid-feedback="$t('global.error.required')"
							label="Marketing Code*"
						/>
					</div>
				</CCol>
				<CCol md="6">
					<div class="form-group mt-3">
						<CInput
							v-model.trim="$v.localBrandCode.$model"
							:is-valid="!$v.localBrandCode.$error && null"
							:invalid-feedback="$t('global.error.required')"
							label="Product ID*"
						/>
					</div>
				</CCol>
				<CCol md="12">
					<hr class="mb-5">
				</CCol>
			</template>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:remove-text="isEdit ? 'Remove plan' : null"
			content-class="main-wrapper"
			:is-edit="isEdit"
			@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			@onConfirm="handleSubmit"
			@onCancel="redirectToPaymentSetting"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteInstallmentPlan.bind(null, { methodId: methodId, planId: this.$route.params.planId })"
			title="Remove this installment plan?"
			description="By removing this, the installment plan and condition will be removed from the system and will no longer available for customers on the Studio7 website."
			@onSuccess="redirectToPaymentSetting"
		/>
	</form>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions } from 'vuex';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import {
	INSTEREST_RATE_KBANK_OPTIONS,
	INSTEREST_RATE_DEFAULT_OPTIONS,
	PAYMENT_GATEWAY,
} from '../enums/payments';
import { priceToAPI, numberDecimalFormat, isBAYBankAgent, isKTCBankAgent } from '../assets/js/helpers';

export default {
	name: 'FormInstallmentPlan',

	validations() {
		return {
			localInterest: {
				required,
			},
			localPeriod: {
				required,
				minValue: minValue(1),
			},
			localBrandCode: {
				required: requiredIf(() => this.isBAYBankAgent),
			},
			localCampaignCode: {
				required: requiredIf(() => this.isBAYBankAgent),
			},
			localMaxOrder: {
				greaterThanMinOrder: (value, { localMinOrder, localMaxOrder }) => {
					if (!isEmpty(localMinOrder) && !isEmpty(localMaxOrder)) {
						return Number(localMaxOrder) > Number(localMinOrder);
					}

					return true;
				},
			},
		};
	},
	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		methodId: {
			type: [String, Number],
			default: null,
		},
		groupId: {
			type: [String, Number],
			default: null,
		},
		agent: {
			type: String,
			default: null,
		},
		channelId: {
			type: [String, Number],
			default: null,
		},
		brandId: {
			type: [String, Number],
			default: null,
		},
		interest: {
			type: Number,
			default: null,
		},
		period: {
			type: Number,
			default: null,
		},
		minOrderAmount: {
			type: String,
			default: null,
		},
		maxOrderAmount: {
			type: String,
			default: null,
		},
		brandCode: {
			type: String,
			default: null,
		},
		campaignCode: {
			type: String,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		gateway: {
			type: String,
			default: null,
		},
		customAttributes: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			localPeriod: null,
			localInterest: null,
			localMinOrder: null,
			localMaxOrder: null,
			localBrandCode: null,
			localCampaignCode: null,
		};
	},
	computed: {
		interestRateOptions() {
			if (this.gateway === PAYMENT_GATEWAY.KBANK) {
				return INSTEREST_RATE_KBANK_OPTIONS;
			}

			return INSTEREST_RATE_DEFAULT_OPTIONS;
		},
		title() {
			return this.isBAYBankAgent ? 'Plan condition' : 'Plan info';
		},
		description() {
			return this.isBAYBankAgent
				? 'Set interest rate and payment period for this plan. The condition can\'t be duplicated with other existing plans.'
				: `Currently, we offer customers up to 36-months installment plan (terms depend on the chosen bank). e.g. ${this.isKTCBankAgent ? 'KTC' : 'Kbank'} fixed at 3, 6 or 10 months with monthly interest at 0.8%`;
		},
		isBAYBankAgent() {
			return isBAYBankAgent(this.agent);
		},
		isKTCBankAgent() {
			return isKTCBankAgent(this.agent);
		},
		maxOrderErrorMessage() {
			if (!this.$v.localMaxOrder.greaterThanMinOrder) {
				return `Maximum order should be more than ${numberDecimalFormat(this.localMinOrder)}`;
			}

			return null;
		},
	},
	mounted() {
		if (this.isEdit) {
			this.localInterest = numberDecimalFormat(this.interest, 2);
			this.localPeriod = this.period;
			this.localMinOrder = this.minOrderAmount;
			this.localMaxOrder = this.maxOrderAmount;
			this.localBrandCode = this.customAttributes.brandCode;
			this.localCampaignCode = this.customAttributes.campaignCode;
		}
	},
	methods: {
		...mapActions({
			deleteInstallmentPlan: 'payments/deleteInstallmentPlan',
		}),

		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const params = {
				interest_rate: this.localInterest,
				period: this.localPeriod,
				min_order_amount: priceToAPI(this.localMinOrder),
				max_order_amount: priceToAPI(this.localMaxOrder),
				...(
					this.isBAYBankAgent
						? { custom_attributes: { brand_code: this.localBrandCode, campaign_code: this.localCampaignCode } }
						: null
				),
				is_default_plan: true,
			};

			this.$emit('onSubmit', params);
		},
		redirectToPaymentSetting() {
			if (this.brandId) {
				this.$router.push({
					name: 'BrandInstallmentPlanEdit',
					params: {
						id: this.groupId,
						methodId: this.methodId,
						brandId: this.brandId,
					},
				});
			} else if (this.channelId) {
				this.$router.push({
					name: 'InstallmentPlanChannel',
					params: {
						id: this.groupId,
						methodId: this.methodId,
						channelId: this.channelId,
					},
				});
			} else {
				this.$router.push({
					name: 'PaymentMethodSetting',
					params: {
						id: this.groupId,
						methodId: this.methodId,
					},
				});
			}
		},
		handleRemovePlan() {
			this.redirectToPaymentSetting();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-period {
		.form-control-number {
			// override padding left append text
			padding-right: rem(60) !important;
		}
	}
</style>
