<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormInstallmentPlan
		v-else
		:interest="plan.edit.data.interestRate"
		:period="plan.edit.data.period"
		:min-order-amount="plan.edit.data.minOrderAmount"
		:max-order-amount="plan.edit.data.maxOrderAmount"
		:custom-attributes="plan.edit.data.customAttributes"
		:agent="PAYMENT_GATEWAY.BAY"
		:is-submitting="plan.edit.isUpdating"
		:group-id="groupId"
		:method-id="methodId"
		:brand-id="brandId"
		:gateway="paymentMethodDetails.gateway"
		is-edit
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormInstallmentPlan from '@/components/FormInstallmentPlan.vue';
import { PAYMENT_GATEWAY } from '../enums/payments';

export default {
	name: 'BrandInstallmentPlanConditionEdit',

	components: {
		FormInstallmentPlan,
	},
	data() {
		return {
			PAYMENT_GATEWAY,
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', {
			plan: 'installmentPlanForGroup',
		}),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),

		groupId() {
			return this.$route.params.id;
		},
		methodId() {
			return this.$route.params.methodId;
		},
		brandId() {
			return this.$route.params.brandId;
		},
		planId() {
			return this.$route.params.planId;
		},
	},
	async created() {
		this.isLoading = true;
		await Promise.all([
			this.getPaymentMethod(this.methodId),
			this.getInstallmentPlanForGroup({
				methodId: this.methodId,
				brandId: this.brandId,
				planId: this.planId,
			}),
		]);
		this.isLoading = false;

		// Set breadcrumb
		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: `Installment credit card / ${this.paymentMethodDetails.name}`,
				route: {
					name: 'PaymentMethodSetting',
					params: { id: this.groupId, methodId: this.methodId },
				},
			},
			{
				title: `Installment plan`,
				route: {
					name: 'BrandInstallmentPlanEdit',
					params: { id: this.groupId, methodId: this.methodId, brandId: this.brandId },
				},
			},
		]);
	},
	methods: {
		...mapActions({
			updateInstallmentPlanForGroup: 'payments/updateInstallmentPlanForGroup',
			getPaymentMethod: 'payments/getPaymentMethod',
			getInstallmentPlanForGroup: 'payments/getInstallmentPlanForGroup',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),

		async handleSubmit(params) {
			await this.updateInstallmentPlanForGroup({
				methodId: this.methodId,
				brandId: this.brandId,
				planId: this.planId,
				params,
			});
		},
	},
};
</script>
